<template>
      <v-card>
        <!-- header -->
        <v-app-bar v-if="companyDetail!=undefined" class="elevation-0" dark color="#1f88fe">
          <v-toolbar-title >{{companyDetail.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- botones contacto pc -->
          <div class="hidden-md-and-down">
            <v-btn v-bind:href="'tel:'+companyDetail.phone" icon class="mx-3">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-phone-outline</v-icon>
                </template>
                <span>Llamar al {{companyDetail.phone}}</span>
              </v-tooltip>
            </v-btn>
            <v-btn @click="whatsapp()" icon class="mx-3">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-whatsapp</v-icon>
                </template>
                <span>WhatsApp {{companyDetail.phone}}</span>
              </v-tooltip>
            </v-btn>
            <v-btn v-bind:href="'mailto:'+companyDetail.email" icon class="mx-3">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-email-outline</v-icon>
                </template>
                <span>{{companyDetail.email}}</span>
              </v-tooltip>
            </v-btn>
            <v-btn v-bind:href="'https://www.google.com.mx/maps/place/' + companyDetail.address + '/@'" target="_blank" icon class="mx-3">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-map-marker-outline</v-icon>
                </template>
                <span>{{companyDetail.address}}</span>
              </v-tooltip>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="back()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <!-- container -->
        <v-sheet id="scrolling-techniques-6" class="overflow-y-auto" max-height="calc(100vh - 64px)" min-height="calc(100vh - 64px)" style="max-width: 100vw; margin:auto; background:#f4f5fa;">
          <v-row style="max-width: 100vw; margin:auto;">
            <!-- sidebar -->
            <v-col class="px-4 py-7" cols="12" md="3" >
              <v-card outlined>
                <contacts v-bind:client="company"/>
              </v-card>

              <v-card class="px-6 pa-6 mt-4" outlined>
                <div class="overline mb-2">
                  Recordatorio/Nota Especial
                </div>
                <v-textarea class="space" background-color="amber lighten-4" v-model="companyDetail.special_note"></v-textarea>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn @click="saveNote()" text>
                    GUARDAR
                  </v-btn>
                </v-row>
              </v-card>

              <v-card class="px-6 pa-6 mt-4" outlined>
                <div class="overline mb-2">
                  Datos Fiscales
                </div>
                <div>
                  <span style="font-size:16px;" class="my-2">
                    <strong>RFC: </strong> 
                    {{companyDetail.rfc}}
                  </span>
                </div>
                <div>
                  <span style="font-size:16px;" class="my-2">
                    <strong>Razón Social: </strong> 
                    {{companyDetail.razon_social}}
                  </span>
                </div>
              </v-card>


            </v-col>
            <!-- tabs -->
            <v-col style="background:#f4f5fa;" class="py-6 px-4 mt-2" cols="12" md="9">
                <v-tabs grow class="paddingtab">

                  <v-tab background-color="transparent" color="basil" grow>
                    Actividades
                  </v-tab>
                  <v-tab-item>
                    <activities v-bind:company="company"/>
                  </v-tab-item>
                  
                  <v-tab background-color="transparent" color="basil" grow>
                    Cotizaciones
                  </v-tab>
                  <v-tab-item>
                    <quotations v-bind:company="company"/>
                  </v-tab-item>

                  <v-tab background-color="transparent" color="basil" grow>
                    Ventas
                  </v-tab>
                  <v-tab-item>
                    <sales v-bind:company="company"/>
                  </v-tab-item>

                  <v-tab background-color="transparent" color="basil" grow>
                    Cancelaciones
                  </v-tab>
                  <v-tab-item>
                    <canceled v-bind:company="company"/>
                  </v-tab-item>

                  <v-tab background-color="transparent" color="basil" grow>
                    Notas
                  </v-tab>
                  <v-tab-item>
                    <notes v-bind:company="company"/>
                  </v-tab-item>

                  <v-tab v-show="permissions('activityLog')" background-color="transparent" color="basil" grow>
                    Bitacora
                  </v-tab>
                  <v-tab-item v-show="permissions('activityLog')">
                    <activitylog v-bind:company="company"/>
                  </v-tab-item>

                </v-tabs>
            </v-col>
          </v-row>
        </v-sheet>
        <!-- botones contacto movil -->
        <v-bottom-navigation style="background:#2b2b2b;" class="hidden-md-and-up" fixed bottom>
          <v-btn v-bind:href="'tel:'+company.phone" icon class="mx-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="white" v-bind="attrs" v-on="on">mdi-phone-outline</v-icon>
              </template>
              <span>Llamar al {{company.phone}}</span>
            </v-tooltip>
          </v-btn>
          <v-btn @click="whatsapp()" icon class="mx-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="white" v-bind="attrs" v-on="on">mdi-whatsapp</v-icon>
              </template>
              <span>WhatsApp {{company.phone}}</span>
            </v-tooltip>
          </v-btn>
          <v-btn v-bind:href="'mailto:'+company.email" icon class="mx-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="white" v-bind="attrs" v-on="on">mdi-email-outline</v-icon>
              </template>
              <span>{{company.email}}</span>
            </v-tooltip>
          </v-btn>
          <v-btn v-bind:href="'https://www.google.com.mx/maps/place/' + company.address + '/@'" target="_blank" icon class="mx-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="white" v-bind="attrs" v-on="on">mdi-map-marker-outline</v-icon>
              </template>
              <span>{{company.address}}</span>
            </v-tooltip>
          </v-btn>
        </v-bottom-navigation>
      </v-card>
</template>
<script>
import axios from "axios";
import Contacts from "../contacts/container"
import Activities from "../../activities/table"
import Quotations from "../../quotations/table"
import Sales from "../../sales/table"
import Canceled from "../../canceled/table"
import Notes from "../../notes/container"
import ActivityLog from "../../activitylog/container"
  export default {
    components: {
      'contacts':Contacts,
      'quotations':Quotations,
      'activities':Activities,
      'sales':Sales,
      'canceled':Canceled,
      'notes':Notes,
      'activitylog':ActivityLog
    }, 
    data () {
      return {
        company: this.$route.params.id
      }
    },
    created(){
      this.$emit("closeDrawer", false);
      this.$store.dispatch('company/getCompanies')
    },
    computed:{
      currentUser:{
        get(){
          return this.$store.state.currentUser.user
        }
      },
      companyDetail(){
        return this.$store.state.company.companies.filter(company=> company.id == this.company)[0]
      }
    },
    methods: {
      saveNote(){
        axios.put("https://exotransportbackend.unocrm.mx/api/v1/company/update",Object.assign(this.companyDetail)).then(response=>{
          this.$store.dispatch('company/getCompanies')
        })
      },
      back(){
        this.$emit("closeDrawer", true);
        window.history.back();
      },
      permissions(permission){
        if(this.currentUser.permissions!=undefined){
          if(this.currentUser.permissions.includes(permission)){
            return true
          }else{
            return false
          }
        }else if(this.currentUser.id==1){
            return true
        }else{
            return false
        }
      },
      close () {
        this.$nextTick(() => {
          this.$emit("closeDialogDetailCompany", false);
        })
      },
      whatsapp(){
        if( /Android|iPhone|BlackBerry/i.test(navigator.userAgent) ) {
          var link = "https://wa.me/521"+this.company.phone
          location.replace(link, '_blank')
        }else if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)){
          var link = "https://web.whatsapp.com/send?phone=521"+this.company.phone
          location.replace(link, '_blank')
        }else{
          var link = "https://web.whatsapp.com/send?phone=521"+this.company.phone
          window.open(link, '_blank')
        }
      }
    }
  }
</script>

<style>
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none!important;
}

@media (max-width: 980px){
  .paddingtab .v-data-table {
      padding-right: 0px !important;
      padding-left: 0px !important;
  }
  .paddingtab .detail{
    display: none!important;
  }
}

.space .theme--light.v-input input, .theme--light.v-input textarea {
    padding: 15px 20px;
}
</style>